@font-face {
    font-family: 'icomoon';
    src:    url($fontPath+'/icomoon.eot?ufyx8s');
    src:    url($fontPath+'/icomoon.eot?ufyx8s#iefix') format('embedded-opentype'),
        url($fontPath+'/icomoon.ttf?ufyx8s') format('truetype'),
        url($fontPath+'/icomoon.woff?ufyx8s') format('woff'),
        url($fontPath+'/icomoon.svg?ufyx8s#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-at:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e145";
}
.icon-navigate_before:before {
  content: "\e408";
}
.icon-navigate_next:before {
  content: "\e409";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-crop_free:before {
  content: "\e3c2";
}
.icon-restore:before {
  content: "\e8b3";
}
.icon-link:before {
  content: "\e157";
}
.icon-room:before {
  content: "\e8b4";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-phone_in_talk:before {
  content: "\e61d";
}
.icon-screen_rotation:before {
  content: "\e1c1";
}
.icon-volume_off:before {
  content: "\e04f";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}

