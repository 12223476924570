body {
    font-family: $BodyRegular;
    //min-height:100vh;
    background: black;
    color:white;
}

.noSelect {
    /** Disable text selection **/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html, body {
  overflow:hidden;
  width:100%;
  height:100%;
  min-width: 320px;
    -webkit-tap-highlight-color: transparent;

    &.no-scroll {
        position:fixed;
        overflow:hidden !important;
    }

    &.inactive {
        overflow: hidden !important;
    }
}

body:before {
    // dev mq util
    bottom:0;
    top: auto;
    right:0;
    left:auto;
    display:none;
}

html {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased;
    -webkit-font-smoothing:subpixel-antialiased;

}
.overflowClass {
    overflow:hidden;
}
a img {
    border:0;
}

a {
    -webkit-tap-highlight-color: none;
}

section {
    position: relative;
    width:100%;
}

img {
    width:100%;
    height: auto;
    border: 0;
}

canvas.statsjs {
    //display: none;
}

// scrollbars
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #000;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #666;
}
::-webkit-scrollbar-thumb:active {
  background: #fff;
}
::-webkit-scrollbar-track {
  background: #c3c3c3;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #c3c3c3;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}



a {
    text-decoration: none;
    border:0;
    outline: none;
    cursor: pointer;
    color: $blue2;
    transition: color 0.2s ease-out;

    &:hover {
        color: $red2;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $HeadingBold;
    //letter-spacing: 0.05rem;
    line-height:1.15;
    font-weight: normal;
    margin-top:0;
}

h1 {
    font-size:4rem;
    @include mq($until: large) {
        font-size:3rem;
    }
    @include mq($until: medium) {
        font-size:2.5rem;
    }
    @include mq($until: small) {
        font-size:2rem;
    }
}

h2 {
    font-size:2.5rem;
    @include mq($until: medium) {
        font-size:1.75rem;
    }
}

h3 {
    font-size:1.60rem;
    @include mq($until: medium) {
        font-size:1.25rem;
    }
}

h4 {
    font-size:1.25rem;
    @include mq($until: medium) {
        font-size:1rem;
    }
}

.uppercase {
    text-transform: uppercase;
}


.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */


// layout

#mainContainer {
    width:100%;
    height: 100%;
    //-min-height: 360px;
    top:0;
    left:0;
    position: absolute;
    margin:0;
    padding:0;
    overflow: hidden;
    background: $grey6;
    //background-image: url('/assets/images/squared_metal_@2X.png');
    //background: url('/assets/images/bg-test.jpg') no-repeat center;
    background-size: cover;
    /* overflow-x:hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; */
    backface-visibility: hidden;
    //@include linear-gradient(45deg, rgba(66,158,224,1), rgba(108,192,253,1), 0%, 100% );
    box-shadow: inset 0 0 30px 10px rgba(0,0,0,0.2);

    &:focus{
        outline: none;
        border:0;
    }

    /* &:after {
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        background-image: url('/assets/images/scanline.png');

        opacity: 1;
        z-index: 1;

    } */
}

#cyberpunkRun {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;

    canvas {
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left: 0;
    }
}

.black {
    color: black !important;
}
.white {
    color: white !important;
}
.blue {
    color: $blue !important;
}
.red {
    color: $red !important;
}
.green {
    color: $green !important;
}
.pink {
    color: $pink !important;
}
.blue-bg {
    background-color: $blue;
}
.pink-bg {
    background-color: $pink;
}
.green-bg {
    background-color: $green;
}
.red-bg {
    background-color: $red;
}

@keyframes glitch {
    0% {
        @include translate(-50%,-50%);
    }
    20% {
        @include translate(-55%,-45%);
    }
    40% {
        @include translate(-55%,-55%);
    }
    60% {
        @include translate(-45%,-45%);
    }
    80% {
        @include translate(-45%,-55%);
    }
    to {
        @include translate(-50%,-50%);
    }
}

@keyframes glitchlogo {
    0% {
        @include translate(-50%,-50%);
    }
    20% {
        @include translate(-52%,-48%);
    }
    40% {
        @include translate(-51%,-55%);
    }
    60% {
        @include translate(-48%,-49%);
    }
    80% {
        @include translate(-49%,-53%);
    }
    to {
        @include translate(-50%,-50%);
    }
}

@keyframes borderGlitch {
    0% {
        border:0;
    }
    20% {
        border:0;
        border-top:5px solid black;
    }
    40% {
        border:0;
        border-right:5px solid black;
    }
    60% {
        border:0;
        border-bottom:5px solid black;
    }
    80% {
        border:0;
        border-left:5px solid black;
    }
    to {
        border:0;
    }
}

.glitchBorder {
    animation: borderGlitch 0.5s cubic-bezier(.15, .33, .32, .82) both infinite;
}

// ui

#ui {
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    pointer-events:none;

    // all white
    color: white !important;

    .legend {
        background: rgba(0,0,0,0.8);
    }

}

.hotspot {
    position: absolute;
    pointer-events:all !important;
    cursor: pointer;
    width:50px;
    height:50px;

    .g1, .g2 {
        opacity: 0;
    }

    &.hover {
        .icon {
            text-shadow: 0 0 0 black;
        }
        .legend {
            opacity: 1;
        }
        .g1 {
            animation: glitch .2s cubic-bezier(.15, .33, .32, .82) both infinite;
            opacity: 1;
        }
        .g2 {
            animation: glitch .2s cubic-bezier(.15, .33, .32, .82) reverse both infinite;
            opacity: 1;
        }
    }


    .icon {
        font-size:1.75rem;
        position: absolute;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);
        text-shadow: 0 0px 5px rgba(0,0,0,0.3);
    }

    .legend {
        position: absolute;
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: bold;
        pointer-events:none;
        font-size:0.8rem;
        top:50%;
        @include translate(0,-50%);
        transition: all 0.15s ease-out;
        opacity: 0;
        padding:0.5rem;
        text-shadow: 0 0px 5px rgba(0,0,0,0.7);
        background-color:rgba(255,255,255,0.8);
    }

    &.fullscreen {
        top:0;
        left:0;
        z-index:100;

        .legend {
            left:150%;
            text-align: left;
        }

        &.hover {
            .legend {
                left:100%;
            }
        }
    }

    &.about {
        top:0;
        right:0;
        z-index:98;
        @include translateX(0%);
        opacity: 1;


        &.active {
            @include translateX(100%)
            opacity: 0;
            z-index: 97;
        }

        .icon {
            font-size:2.3rem;
        }

        .legend {
            right:150%;
            text-align: right;
        }

        &.hover {
            .legend {
                right:100%;
            }
        }
    }

    &.closeAbout {
        top:0;
        right:0;
        z-index:97;
        @include translateX(100%);
        opacity: 0;

        &.active {
            @include translateX(0%)
            opacity: 1;
            z-index: 98;
        }

        .icon {
            font-size:2.3rem;
        }

        .legend {
            right:150%;
            text-align: right;
        }

        &.hover {
            .legend {
                right:100%;
            }
        }
    }

    &.contact {
        bottom:0;
        left:0;
        z-index:100;

        .legend {
            left:150%;
            text-align: left;
        }

        &.hover {
            .legend {
                left:100%;
            }
        }
    }

    &.sound {
        bottom:0;
        right:0;
        z-index:100;

        .off, .on {
            display: none;
        }

        &.off {
            .off {
                display: block;
            }
        }

        &.on {
            .on {
                display: block;
            }
        }

        .legend {
            right:125%;
            text-align: right;
        }

        &.hover {
            .legend {
                right:100%;
                //width:120%;
            }
        }
    }
}

/* .contactOpen {
    color: white !important;

    .legend {
        background: rgba(0,0,0,0.8);
    }

    &.fullscreen {
        color: inherit !important;
        .legend {
            background: rgba(255,255,255,0.8);
        }
        @include mq($until: small) {
            color: white !important;
            .legend {
                background: rgba(0,0,0,0.8);
            }
        }
    }
}

.aboutOpen,.hotspot.active {
    color: white !important;

    &.fullscreen {
        color: white !important;
        .legend {
            background: rgba(0,0,0,0.8) !important;
        }
    }

    .legend {
        background: rgba(0,0,0,0.8) !important;
    }
} */


// contact panel

.logo  {
    width:100%;
    max-width: 320px;
    height:200px;
    position: absolute;
    background-image:url('/assets/images/PWNK_logo_white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    margin: 0 auto;
    margin-bottom:1rem;
    transition: all 0.2s ease-out;

    @include mq($until:large) {
        height:180px;
        max-width: 280px;
        margin-bottom: 0.5rem;
    }

    @include mq($until:small) {
        height:40vh;
        margin-bottom: 0;
    }

    &.black {
        background-image:url('/assets/images/PWNK_logo_black.svg');
    }

    &.pink {
        background-image:url('/assets/images/PWNK_logo_pink.svg');
    }

    &.blue {
        background-image:url('/assets/images/PWNK_logo_blue.svg');
    }
}


#contactPanel {
    width: 100%;
    position: absolute;
    bottom:0;
    left:0;
    background: black;
    height: 280px;
    overflow: hidden;
    z-index:99;
    //border-top:4px solid white;
    transition: height 0.2s ease-out;

    @include translateY(100%);

    @include mq($until:medium) {
        height:440px;
    }

    @include mq($until:small) {
        height:100%;
    }

    &.active {
        @include translateY(0%);
    }

    .bg-static {
        width: 100%;
        position: absolute;
        height:100%;
        background-image:url('/assets/images/contact_bg.gif');
        background-size:cover;
        background-position: top center;
        opacity: 0.3;

    }

    .hotspot.about {
        z-index: 100;

        .close {
            display: block;
        }

        .legend {
            background: rgba(0,0,0,0.8);
        }
    }

    .content {
        width:100%;
        height: 100%;
        overflow-x:hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding:2.5rem 0.5rem 1.5rem 0.5rem;
        position: relative;

        @include translateY(100%);

        &.active {
            @include translateY(0%);
        }

        @include mq($until:small) {
            padding:3rem 0 0rem 0;
        }


        .contactDetails {
            font-size: 1.2rem;
            text-align: left;

            .ct {
                margin-bottom: 0.5rem;
            }

            .icon {
                font-size: 1.5rem;
                margin-right:1rem;
                display: inline-block;
            }

            @include mq($until:wide) {
                font-size:1.1rem;
                .icon {
                    font-size:1.35rem;
                }
            }


            @include mq($until:medium) {
                font-size:0.95rem;
                text-align: center;
                .icon {
                    font-size:1.2rem;
                    margin-right:0.5rem;
                }
            }
            a {
                color: white;
                &:hover {
                    color: $blue;
                }
            }

            span.copy {
                vertical-align: top;
            }

            .socialLink {
                width:40px;
                height:40px;
                border-radius: 50%;
                background-color: white;
                color: black;
                position: relative;
                display: inline-block;
                margin-right: 1rem;
                margin-top: 0.5rem;
                transition: all 0.2s ease-out;


                &:hover {
                    background-color: $pink;
                    color: black;
                }

                .icon {
                    font-size:1.2rem;
                    position: absolute;
                    top:50%;
                    left:50%;
                    @include translate(-50%,-50%);
                    line-height: 0;
                }
            }

        }
    }
}


// ABOUT panel

#aboutPanel {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    //left:-100%;
    opacity: 0;
    color: $grey2;
    z-index: 10;

    &.active {
        //left:0%;
        opacity: 1
    }

    .contentHolder {
        opacity: 0;
        padding-top:2rem;
        padding-bottom: 2rem;
        width:100%;
        height:100%;
        position: fixed;
        top:0;
        left:0;
        overflow: hidden;
        vertical-align: middle;
        white-space: nowrap;

        padding-right: 2rem;
        padding-left: 2rem;

        @include mq($until:medium) {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }

        .content {
            position: relative;
            display: inline-block;
            white-space:normal;
            vertical-align: middle;
            margin: 0 auto;
            width: 100%;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          margin-right: -0.25em; /* Adjusts for spacing */
        }

        &.scrollable {
            overflow-x:hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        &.active {
            opacity: 1;
        }

        @include mq($until:medium) {
            padding-top:3rem;
        }
    }

    .bg {
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        background: rgba(0,0,0,0.5);
        opacity: 0;

        &.active {
            opacity: 1;
        }
    }

    .logoHolder {
        width:50%;
        position: fixed;
        left:0;
        bottom:5vw;
        height:200px;
        max-width: 1800px;
    }

    .logo {
        max-width: 800px;
        height:16vw;
        max-height: 300px;
        width: 80%;
        position: absolute;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);

        &.g1 {
            animation: glitchlogo .2s cubic-bezier(.15, .33, .32, .82) both infinite;
            opacity: 1;
        }
        &.g2 {
            animation: glitchlogo .2s cubic-bezier(.15, .33, .32, .82) reverse both infinite;
            opacity: 1;
        }
    }

    .logo-mobile {
        width:100%;
        height:200px;
        position: relative;
        margin-bottom: 0rem;

        .logo {
            height:200px;
        }
    }

    .content {

        @include mq($until:medium) {
            text-align: center;
        }

        h1 {
            margin:0;
            color: $grey6;
            text-shadow: 0 0px 5px rgba(255,255,255,0.7);
            font-weight: 100;

            @include mq($until:wide) {
                font-size: 3.25rem;
            }
            @include mq($until:large) {
                font-size: 3rem;
            }

            @include mq($until:medium) {
                font-size: 2.5rem;
            }
        }

        p {
            color: white;
            font-size: 1.4rem;
            line-height:1.35;

            @include mq($until:wide) {
                font-size: 1.2rem;
            }
            @include mq($until:medium) {
                font-size: 1.1rem;
            }
        }

        a {
            color: $blue !important;
            &:hover {
                color: $pink !important;
            }
        }
    }
}
