/* Float container collapse fix */
@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


/* Text replacement */
@mixin text-replacement($width, $height, $image) {
    width: $width;
    height: $height;
    background: url($image) center center no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}


/* Visually hide an element */
@mixin visually-hidden {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}


/* Transform mixins */
@mixin transform($transforms) {
    // -o-transform: $transforms;
    // -moz-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
@mixin scaleX($scale) {
    @include transform(scaleX($scale));
}
@mixin scaleY($scale) {
    @include transform(scaleY($scale));
}
// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

@mixin translateX($x) {
    @include transform(translateX($x));
}

@mixin translateY($y) {
    @include transform(translateY($y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin skewX($x) {
    @include transform(skewX(#{$x}deg));
}
@mixin skewY($y) {
    @include transform(skewY(#{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}


/* Gradients */
@mixin linear-gradient($direction: 135deg, $startColor: #fff, $stopColor: #000, $startPos: 0%, $endPos: 100%) {
    background: $startColor; // fallback
    // background: -o-linear-gradient($direction, $startColor 0%, $stopColor 100%); // Opera 11.10+
    // background: -moz-linear-gradient($direction, $startColor 0%, $stopColor 100%); // FF3.6+
    background: -ms-linear-gradient($direction, $startColor $startPos, $stopColor $endPos); // IE 10+
    // background: -webkit-linear-gradient($direction, $startColor 0%, $stopColor 100%); // Chrome10+,Safari5.1+
    background: linear-gradient($direction, $startColor $startPos, $stopColor $endPos); // W3C
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$startColor}', endColorstr='#{$stopColor}', GradientType=1 ); // IE6-9 fallback on horizontal gradient
}


/* Site specific */
// Copy
@mixin small-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
}


// Text sizes
@mixin tinyText {
    font-size: 0.687rem;
    letter-spacing: 0.025rem;
    line-height: 1.125rem;
}
@mixin fontSizeDefault {
    font-size: 1rem;
    line-height: 1.562rem;
}
@mixin fontSizeH1 {
    font-size: 2.125rem;
    letter-spacing: 0.2rem;
    line-height: 2.687rem;
}
@mixin fontSizeH2 {
    font-size: 1.56rem;
    letter-spacing: 0.135rem;
    line-height: 1.875rem;
}
@mixin fontSizeH3 {
    font-size: 1rem;
    letter-spacing: 0.055rem;
    line-height: 1.562rem;
}
@mixin fontSizeH4 {
    font-size: 0.875rem;
    letter-spacing: 0.025rem;
    line-height: 1.562rem;
}
@mixin large-text {
    font-size: 1.125rem;
    line-height: 1.875rem;
}
@mixin align-left {
    text-align: left;
}
@mixin align-right {
    text-align: right;
}
@mixin align-center {
    text-align: center;
}

// Gradients
@mixin gradient-blue-light {
    @include linear-gradient(135deg, $blue-light, darken($blue-light, 5%));
}
@mixin gradient-blue-medium {
    @include linear-gradient(135deg, $blue-medium, darken($blue-medium, 5%));
}
@mixin gradient-blue-medium-reversed {
    @include linear-gradient(225deg, $blue-medium, darken($blue-medium, 5%));
}
@mixin gradient-blue-medium-secondary {
    @include linear-gradient(135deg, $blue-medium-secondary, darken($blue-medium-secondary, 5%));
}
@mixin gradient-green-medium {
    @include linear-gradient(135deg, $green-medium, darken($green-medium, 5%));
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// fixed image clip fix for safari
@mixin safari-clip-fix {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        _::-webkit-full-page-media, _:future, :root & {
            clip: auto;
            -webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%);
        }
    }
}



// animations
@mixin textGlitch($name, $intensity, $textColor, $background, $highlightColor1, $highlightColor2, $width, $height) {

  color: $textColor;
  position: relative;
  $steps: $intensity;

  // Ensure the @keyframes are generated at the root level
  @at-root {
    // We need two different ones
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height)+px,
              $width+px,
              random($height)+px,
              0
            );
          }
        }
      }
    }
  }
  &:before,
  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $background;
    clip: rect(0, 0, 0, 0);
  }
  &:after {
    left: 2px;
    text-shadow: -1px 0 $highlightColor1;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  &:before {
    left: -2px;
    text-shadow: 2px 0 $highlightColor2;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }

}
