/* Asset Paths */
$assetPath: '/assets/';
$fontPath: $assetPath + 'fonts/';
$imagePath: $assetPath + 'images/';

$mq-breakpoints: (
    mobile:  320px,
    tablet:  768px,
    desktop: 980px,
    wide:    1800px
);

/* Breakpoints */
$extraSmallScreen: 320px;
$smallScreen: 480px;
$mediumScreen: 768px;
$largeScreen: 980px;
$wideScreen: 1800px;


/* Bootstrap Grid */
$grid-columns: 12;
$grid-gutter-width: 12px;

$colorBarHeight: 0.45rem;
$navBarHeight: 4.5rem;
$navBarHeightMobile: 3.5rem;

$screen-sm-min: $smallScreen;
$screen-md-min: $mediumScreen;
$screen-lg-min: $largeScreen;
$screen-wi-min: $wideScreen;

$container-sm: $smallScreen + $grid-gutter-width;
$container-md: $mediumScreen + $grid-gutter-width;
$container-lg: $largeScreen + $grid-gutter-width;
$container-wi: $wideScreen + $grid-gutter-width;

/* Z-Index Scale */
$zz-bottom: 0;
$zz-1: 10;
$zz-2: 20;
$zz-3: 30;
$zz-4: 40;
$zz-5: 50;
$zz-6: 60;
$zz-7: 70;
$zz-8: 80;
$zz-9: 90;
$zz-top: 100;


/* Colours */
// Brand colours
$blue: #3DC1F1;
$blue2: #2455a6;
$blue3: #3d9add;
$green: #ADD115;
$pink: #FF00E7;
$purple: #9d81f7;
$orange: #FF9426;
$red: #d33c42;
$red2:#e7440c;
$grey: #3a3a3a;
$grey2: #5a5a5a;
$grey3: #222;
$grey4: #707070;
$grey5: #969A9C;
$grey6: #dadada;



// Others
$white: #ffffff;
$ghost-grey: #5e6368;
$blue-highlight: #222e35;
$youtube-red: #e62c27;
$twitter-blue: #55acee;


/* Fonts */
// Size scale
// See mixins for useful mixins for the standard font sizes on the site

// Headings
$HeadingBold: 'Maven Pro', sans-serif;
$HeadingRegular: 'Maven Pro', sans-serif;

// Body
/* $BodyBold: 'Futura', sans-serif;
$BodyRegular: 'Futura', sans-serif; */
$BodyBold: 'Maven Pro', sans-serif;
$BodyRegular: 'Maven Pro', sans-serif;

/* Animation */
$transition-duration-fast: 0.2s;
$transition-duration: 0.5s;
$transition-duration-button: 0.3s;
$easing: ease-out;

/* End Global Header */
